import {React,useState} from 'react';
import { Box, TextField, Typography ,Grid, Container,Button} from "@mui/material";
import axios from 'axios';

import { ToastContainer,toast,Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HomePage=()=>{
    const[otp,setOtp] = useState();
    const [dataFrom,setDataFrom] = useState({});
    const[formSubmitted,setFormSubmitted] = useState(false);
    const changeHandler=(e)=>{
        setOtp(e.target.value)
    }
    const onSubmitOtp = async(e)=>{
        e.preventDefault();
        try{
            console.log("submit code",otp);
            const response = await axios.get(`https://www.educationpvt.com/apis/user/${otp}`);
            console.log(response);
            console.log("I will check this:",response.data.data[0]);
            console.log(response.data.success);
            if(response.data.success){
                const fetchedData = response.data.data[0];
                setDataFrom(fetchedData);
                // console.log(fetchedData)
                const createdTime = fetchedData.created_time;
                const currentTime = Date.now();
                const timeDifference = currentTime-createdTime;
                const timeForOtp = 1*60*60*1000;
                console.log(createdTime);
                console.log(currentTime);
                console.log(timeDifference);
                console.log(timeForOtp);
                if(timeDifference>=timeForOtp){
                    setFormSubmitted(true);
                }else{
                    toast.warning("OTP is validate after 1 hour from registration.",{
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    setFormSubmitted(false);
                }
                // console.log(formSubmitted);
            }else{
                toast.error("Sorry, OTP doesn't match. Register first.",{
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                setFormSubmitted(false);
            }
        }catch(err){
            console.log(err);
        }
        
    }
    return(
        <Box
            sx={{
                backgroundColor:"#f0f0f0",
                height: "100vh",
                width: "100%",
                display:"flex",
                flexDirection:"column",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Typography variant="h5" fontFamily="Roboto Serif" gutterBottom
                sx={{
                    background: "linear-gradient(270deg, #ebb635, #41a8b8, #ff5f6d, #ffc371)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    display: "inline-block"
                }}
            >10th Nepal Higher Education Fair 2024</Typography>
            <Typography variant="h3" fontFamily="Roboto Serif" mb="30px">Verify Student</Typography>
            <Container maxWidth="sm"
                sx={{
                    border: "2px solid rgba(0,0,0,0.5)",
                    padding:"30px",
                    borderRadius:"5px"
                }}
            >
                <form onSubmit={onSubmitOtp} method="post">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="otp"
                                label="Your OTP"
                                variant="outlined"
                                onChange={changeHandler}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Submit
                            </Button>
                            </Grid>
                    </Grid>
                </form>
            {
                formSubmitted ? (
                    <Box
                        sx={{
                            // border: "1px solid black",
                            mt: "30px",
                            padding: "10px"
                            
                        }}
                        >
                            <Typography variant="h5" fontFamily="Roboto Serif">Name:{dataFrom.name}</Typography>
                            <Typography variant="h5" fontFamily="Roboto Serif">Interested Program:{dataFrom.interested_program}</Typography>
                            <Typography variant="h5" fontFamily="Roboto Serif">Email:{dataFrom.email}</Typography>
                            <Typography variant="h5" fontFamily="Roboto Serif">OTP:{dataFrom.otp}</Typography>
                    </Box>
                ):''
            }
            </Container>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition= {Flip}
            />
        </Box>
    );
}
export default HomePage;
import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { keyframes } from "@emotion/react";
import eduFairLogo from "../../assets/logo/edu_fair_with_shadow.png";

// Keyframes for text animation: move up from below middle and fade in
const moveUpAndFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Keyframes for logo animation: fade in, zoom in, and rotate
const scaleInBlur = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
    filter: blur(10px);
  }
  to {
    opacity: 1;
    transform: scale(1);
    filter: blur(0px);
  }
`;

// Keyframes for fade-out effect
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const WelcomeMessage = ({ onComplete }) => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [fadeOutEffect, setFadeOutEffect] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleIndex((prev) => {
        if (prev < 2) {
          return prev + 1;
        } else {
          clearInterval(interval);
          setTimeout(() => {
            setFadeOutEffect(true);
            setTimeout(() => {
              onComplete(); // Notify parent component to show the registration form
            }, 500); // Duration of the fade-out effect
          }, 1000); // Duration to wait before starting the fade-out effect after the last item is shown
          return prev;
        }
      });
    }, 1000); // Interval for showing each part of the message

    return () => clearInterval(interval);
  }, [onComplete]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(270deg, #ebb635, #41a8b8, #ff5f6d, #ffc371)",
        backgroundSize: "cover",
        position: "relative",
        overflow: "hidden",
        padding: "0 20px",
        flexDirection: "column",
        animation: fadeOutEffect ? `${fadeOut} 0.5s forwards` : undefined,
        "@media (max-width: 600px)": {
          padding: "0 10px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          "@media (max-width: 600px)": {
            gap: "10px",
          },
        }}
      >
        <Box
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            visibility: visibleIndex >= 0 ? "visible" : "hidden",
            "@media (max-width: 600px)": {
              height: "40px",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#fff",
              opacity: visibleIndex >= 0 ? 1 : 0,
              transition: "opacity 0.5s ease-out",
              animation: visibleIndex === 0 ? `${moveUpAndFadeIn} 1s ease-out forwards` : undefined,
              textAlign: "center",
              fontWeight: "bold",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
              "@media (max-width: 600px)": {
                fontSize: "1.5rem",
              },
            }}
          >
            Welcome
          </Typography>
        </Box>
        <Box
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            visibility: visibleIndex >= 1 ? "visible" : "hidden",
            "@media (max-width: 600px)": {
              height: "40px",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#fff",
              opacity: visibleIndex >= 1 ? 1 : 0,
              transition: "opacity 0.5s ease-out",
              animation: visibleIndex === 1 ? `${moveUpAndFadeIn} 1s ease-out forwards` : undefined,
              textAlign: "center",
              fontWeight: "bold",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
              "@media (max-width: 600px)": {
                fontSize: "1.5rem",
              },
            }}
          >
            To
          </Typography>
        </Box>
        <Box
          sx={{
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            visibility: visibleIndex >= 2 ? "visible" : "hidden",
            "@media (max-width: 600px)": {
              height: "150px",
            },
          }}
        >
          <Box
            component="img"
            src={eduFairLogo}
            alt="Education Fair Logo"
            sx={{
              height: "100%",
              width: "auto",
              opacity: 0,
              animation: visibleIndex === 2 ? `${scaleInBlur} 1s ease-out forwards` : undefined,
              "@media (max-width: 600px)": {
                height: "120px",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WelcomeMessage;

import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';

const Test = () => {
    // const day1 = '2024-08-09';
    // const day2 = '2024-08-10';
    // const firstDayCountDown = [12, 14, 16];
    // const secondDayCountDown = [12, 14, 16, 17];
    // const countDownFor = 10; // in minutes

    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    const [countActive, setCountActive] = useState(true);

    // const calculateTimeRemaining = useCallback(() => 
    // {
    //     const now = new Date();
    //     const today = now.toISOString().split('T')[0];
    //     console.log(today);
    //     let nextEventTime;

    //     // Determine the target times for each event, minus 10 minutes
    //     const targetTimes = [];

    //     if (today === day1) {
    //         firstDayCountDown.forEach(hour => {
    //             const eventTime = new Date(`${day1}T${String(hour).padStart(2, '0')}:00:00`);
    //             targetTimes.push(new Date(eventTime.getTime() - countDownFor * 60000));
    //         });
    //     } else if (today === day2) {
    //         secondDayCountDown.forEach(hour => {
    //             const eventTime = new Date(`${day2}T${String(hour).padStart(2, '0')}:00:00`);
    //             targetTimes.push(new Date(eventTime.getTime() - countDownFor * 60000));
    //         });
    //     }

    //     // Find the next event time from the target times
    //     nextEventTime = targetTimes.find(time => time > now);

    //     if (nextEventTime) {
    //         const timeRemaining = nextEventTime - now;

    //         if (timeRemaining > 0) {
    //             setMinutes(Math.floor(timeRemaining / 60000));
    //             setSeconds(Math.floor((timeRemaining % 60000) / 1000));
    //         } else {
    //             setCountActive(false);
    //         }
    //     } else {
    //         setCountActive(false);
    //     }
    // });

    useEffect(() => {
        // const interval = setInterval(() => {
        //     calculateTimeRemaining();
        // }, 1000);
        if (countActive) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else if (minutes > 0) {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                } else {
                    setCountActive(false);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
        // return () => clearInterval(interval);
    }, [minutes,seconds,countActive]);

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Container
                maxWidth="sm"
                sx={{
                    border: '1px solid black',
                    padding: '30px',
                }}
            >
                {countActive ? (
                    <Box
                        sx={{
                            border: '1px solid red',
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 3,
                        }}
                    >
                        {/* countdown */}
                        <Box
                            sx={{
                                backgroundColor: 'green',
                                padding: '30px',
                            }}
                        >
                            {minutes} Minutes
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: 'yellow',
                                padding: '30px',
                            }}
                        >
                            {seconds} Seconds
                        </Box>
                    </Box>
                ) : (
                    <Box>Lists</Box>
                )}
            </Container>
        </Box>
    );
};

export default Test;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import RegistrationForm from './components/registration/registrationForm';
import MyComponent from './components/OTP/OtpInput';
import './global.css';
// import CountDown from './components/Countdown/countdown';
import HomePage from './components/homepage/homepage';
import Example from './components/check';
import Test from './components/Countdown/test';
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/register' element={<RegistrationForm />} />
        <Route path='/' element={<HomePage />} />
        {/* <Route path='/countdown' element={<CountDown />} /> */}
        <Route path='/otpinputoutput' element={<MyComponent />} />
        <Route path='/example' element={<Example />} />
        <Route path='/test' element={<Test />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Grid, Box } from '@mui/material';
import Welcome from '../welcomepage/welcome';

function RegistrationForm() {
  const [showWelcome, setShowWelcome] = useState(true);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [otpStatus, setOtpStatus] = useState('');
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWelcome(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let timer;
    if (otpGenerated && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpGenerated, countdown]);

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString(); // Generates a 6-digit OTP
  };

  const handleRegister = (event) => {
    event.preventDefault();
    
    // Generate OTP and set a 5-minute timer
    setOtpCode(generateOtp());
    setCountdown(300); // 5 minutes in seconds

    // OTP will be available after 5 minutes
    setTimeout(() => {
      setOtpGenerated(true);
      alert('OTP has been generated and will be available in a few minutes.');
    }, 5000); // 5 seconds for testing, change to 5 * 60 * 1000 for actual 5 minutes
  };

  const handleOtpValidation = (event) => {
    event.preventDefault();
    if (otp === otpCode) {
      setOtpStatus('OTP is valid!');
    } else {
      setOtpStatus('OTP is invalid.');
    }
  };

  if (showWelcome) {
    return <Welcome />;
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          backgroundImage: `url('https://images.unsplash.com/photo-1501622549218-2c3ef86627cb?q=80&w=1473&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(5px)',
          zIndex: -1,
        }}
      />
      <Container
        maxWidth="sm"
        sx={{
          position: 'relative',
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
          padding: '2rem',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          zIndex: 1,
        }}
      >
        <Typography
          sx={{ textAlign: 'center', color: '#333' }}
          variant="h4"
          gutterBottom
        >
          Student Registration Form
        </Typography>
        <form onSubmit={handleRegister}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Student Name"
                variant="outlined"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="+2 College"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mobile Number"
                variant="outlined"
                required
                type="tel"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Register
              </Button>
            </Grid>
            {otpGenerated && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Enter OTP"
                    variant="outlined"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleOtpValidation}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Validate OTP
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="textSecondary">
                    Time left: {Math.floor(countdown / 60)}:{countdown % 60}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color={otpStatus === 'OTP is valid!' ? 'green' : 'red'}>
                    {otpStatus}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </Container>
    </Box>
  );
}

export default RegistrationForm;

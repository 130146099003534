import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import axios from "axios";
import { ToastContainer,toast,Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WelcomeMessage from "../welcomepage/welcome";
import { keyframes } from "@emotion/react"; // Import keyframes

function RegistrationForm() {
  const [showWelcome, setShowWelcome] = useState(true);
  const [registerForm,setRegister] =useState({
    name:'',
    passed_college:'',
    phone:'',
    email:'',
    interested_program:''
  });

  const [showForm, setShowForm] = useState(false);
  const changeHandler=(e)=>{
    const{name,value}=e.target;
    setRegister({...registerForm,[name]:value});
  }

// Keyframes for fade-in effect
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

  // const [showWelcome, setShowWelcome] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWelcome(false);
      setShowForm(true);
    }, 3000); // Adjust this duration if needed
    return () => clearTimeout(timer);
  }, []);
  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString(); // Generates a 6-digit OTP
  };
  const handleSubmit = async(event) => {
    event.preventDefault();
    const otp=generateOtp();
    console.log("Form submitted");
    console.log(registerForm);
    console.log(registerForm.name);
    
    console.log(generateOtp);
    try {
      const formData ={...registerForm,otp:otp}
      console.log(formData);
      const response = await axios.post('https://www.educationpvt.com/apis/user', formData);
      console.log('Form submitted successfully:', response.data.success);
      if(response.data.success){
        toast.success("Registration Success.",{
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          // transition: {Flip},
        });
        setRegister({ // Reset form fields
          name: '',
          passed_college: '',
          phone: '',
          email: '',
          interested_program: ''
        });
      }
    } catch (err) {
      console.error('Error submitting form:', err);
      toast.error("Sorry Missing something.Unable to submit.",{
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  if (showWelcome) {
    return <WelcomeMessage onComplete={() => setShowWelcome(false)} />;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        animation: showForm ? `${fadeIn} 1s ease-in` : undefined,
        padding: "0 16px", // Responsive padding
        "@media (max-width: 600px)": {
          padding: "0 8px", // Smaller padding for mobile view
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "100%",
          backgroundImage: `url('https://images.unsplash.com/photo-1501622549218-2c3ef86627cb?q=80&w=1473&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          filter: "blur(5px)",
          zIndex: -1,
        }}
      />
      <Container
        maxWidth="sm"
        sx={{
          position: "relative",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "2rem",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          zIndex: 1,
          animation: showForm ? `${fadeIn} 1s ease-in` : undefined,
          "@media (max-width: 600px)": {
            padding: "1rem", // Responsive padding
          },
        }}
      >
        <Typography
        className="responsive_fontsize32"
          sx={{ textAlign: "center", color: "#333", mb: 2 }} // Margin bottom
          variant="h4"
          gutterBottom
        >
          Student Registration Form
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
                name="name"
                value={registerForm.name}
                onChange={changeHandler}
                fullWidth
                label="Student Name"
                variant="outlined"
                required
                sx={{ mb: 2 }} // Margin bottom for spacing
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="passed_college"
                value={registerForm.passed_college}
                onChange={changeHandler}
                fullWidth
                label="+2 College"
                variant="outlined"
                required
                sx={{ mb: 2 }} // Margin bottom for spacing
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="phone"
                value={registerForm.phone}
                onChange={changeHandler}
                fullWidth
                label="Mobile Number"
                variant="outlined"
                required
                type="tel"
                sx={{ mb: 2 }} // Margin bottom for spacing
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                value={registerForm.email}
                onChange={changeHandler}
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                required
                sx={{ mb: 2 }} // Margin bottom for spacing

              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="interested_program"
                value={registerForm.interested_program}
                onChange={changeHandler}
                required
                fullWidth
                label="Interested Program"
                variant="outlined"

                sx={{ mb: 2 }} // Margin bottom for spacing

              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition= {Flip}
      />
    </Box>
  );
}
export default RegistrationForm;

import React from 'react';
import { toast,ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
function Example(){
  const toastId = React.useRef(null);

  const notify = () => toastId.current = toast.success("Lorem ipsum dolor",{
    position:"top-right"
  });

  const dismiss = () =>  toast.dismiss(toastId.current);

  const dismissAll = () =>  toast.dismiss();

  return (
    <div>
      <button onClick={notify}>Notify</button>
      <button onClick={dismiss}>Dismiss</button>
      <button onClick={dismissAll}>Dismiss All</button>
      <ToastContainer />
    </div>
  );
}
export default Example;